import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

import Container from "~components/Container"
import Layout from "../layouts"
import SEO from "~components/SEO"
import Hero from "~components/Hero"

const IndexPage = ({ data }) => {
  const { avatarImage, bannerImageMobile, bannerImageDesktop } = data

  const images = withArtDirection(getImage(bannerImageMobile), [
    {
      media: "(min-width: 768px)",
      image: getImage(bannerImageDesktop),
    },
  ])

  return (
    <Layout>
      <SEO title="Accueil" />

      <Hero background={images} />

      <section className="tw-mt-8">
        <Container>
          <h1 className="h1 tw-text-center">Votre guide</h1>

          <p className="subtitle tw-text-center">
            Un guide-conférencier français/anglais indépendant au parcours
            atypique
          </p>

          <div className="tw-bg-white tw-rounded-xl tw-shadow tw-p-5 tw-mt-8">
            <div className="media">
              <GatsbyImage
                image={getImage(avatarImage)}
                alt="portrait de Max"
                width="217"
                placeholder="none"
                className="tw-h-full tw-w-full tw-self-center tw-rounded-full md:tw-self-start md:tw-rounded-none"
              />

              <div>
                <p className="tw-mb-4">
                  &laquo;&nbsp;<strong>Diplômé du CNAM</strong> depuis décembre
                  2017 en Licence Professionnelle de guide-conférencier,{" "}
                  <strong>passionné d’Histoire</strong> depuis toujours, grand
                  voyageur, auteur de chansons et comédien, j’exerce avec un
                  enthousiasme et une fraîcheur appréciés de tous. Je privilégie
                  des <strong>groupes à taille humaine</strong> (20 à 25
                  personnes maximum) pour le confort et le plaisir de chacun.
                </p>

                <p className="tw-mb-4">
                  Vous serez équipé(e) d'un système audio performant avec{" "}
                  <strong>oreillette ultra légère et confortable</strong>.
                </p>

                <p className="tw-mb-4">
                  Un style personnel, des visites vivantes, riches de
                  connaissances, d’anecdotes, d’humour qui ont déjà fidélisé
                  nombre de visiteurs, et un catalogue qui s’enrichit
                  régulièrement pour vous proposer, à l’avenir, un choix
                  toujours plus large de sites passionnants et
                  variés.&nbsp;&raquo;
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query IndexPageQuery {
    bannerImageMobile: file(
      relativePath: { eq: "banners/home-page-banner-mobile.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    bannerImageDesktop: file(
      relativePath: { eq: "banners/home-page-banner.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    avatarImage: file(relativePath: { eq: "avatar.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 217
        )
      }
    }
  }
`

export default IndexPage
